import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import uuid from 'react-uuid';
import { postcodeValidator } from 'postcode-validator';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '@/store/slices/userSlice';
import validateEmail from '@/utils/helpers';

// compoents
import Footer from '@/components/Footer';
import ScrollToTop from '@/components/ScrollToTop';

// assets
import logo from '@/assets/img/logo.svg';
import loader from '@/assets/img/loader.gif';
import months from '@/assets/json/months';
import years from '@/assets/json/years';

const SignupPage = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const formDataModel = {
    product_id: '',
    signup_id: uuid(),
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    address: '',
    city: '',
    zip: '',
    phone: '',
    card_type: '',
    card_number: '',
    card_code: '',
    card_expire_month: '',
    card_expire_year: '',
  };

  const [packagesLoading, setPackagesLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [formErrors, setFormErrors] = useState([]);
  const [formData, setFormData] = useState(formDataModel);
  const [loading, setLoading] = useState(false);
  const [formResponseError, setFormResponseError] = useState(null);

  const getPackages = async () => {
    try {
      const { data } = await axios.post(
        'https://jacone-progenitorgeoapi-backend-prod.donkeymob.com/api/1/site/get-products'
      );
      for (const product of data?.result?.products) {
        if (product.id == id) {
          const selection = product;
          setProduct(selection);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPackagesLoading(false);
    }
  };

  const checkoutFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const errors = {};

    setFormErrors([]);

    // Check required fields
    for (const data in formData) {
      if (!formData[data]) {
        errors[data] = 'This is field is require';
      }
    }

    // Validate email
    if (formData.email) {
      const emailAddress = validateEmail(formData.email);
      if (!emailAddress) {
        errors.email = 'Please enter a valid email address';
      }
    }

    // Validate US zip code
    if (formData.zip) {
      const isValidZip = postcodeValidator(formData.zip, 'US');
      if (!isValidZip) {
        errors.zip = 'Please enter a valid zip code';
      }
    }

    // Validate card string (only numbers and 16 char)
    if (formData.card_number) {
      const containsOnlyNumbers = /^\d+$/.test(formData.card_number);
      const stringLength = formData.card_number.length;
      if (!containsOnlyNumbers || stringLength !== 16) {
        errors.card_number = 'Please enter a valid creadit card number';
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setLoading(false);
    } else {
      try {
        // Turn card_month and card_year to integers
        formData.card_expire_month = parseInt(formData.card_expire_month);
        formData.card_expire_year = parseInt(formData.card_expire_year);

        const { data } = await axios.post(
          'https://jacone-progenitorgeoapi-backend-prod.donkeymob.com/api/1/site/signup',
          // 'api/1/site/signup',
          formData
        );

        if (data.status === 200) {
          setFormData(formDataModel);
          dispatch(setUser(data?.result?.user));
        }

        navigateTo('/admin');
      } catch (err) {
        if (err?.response?.data?.error?.message) {
          setFormResponseError(err?.response?.data?.error?.message);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  function inputOnChange(e) {
    const label = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [label]: value });
  }

  useEffect(() => {
    getPackages();
  }, []);

  useEffect(() => {
    if (product) {
      formData.product_id = product.id;
    }
  }, [product]);

  return (
    <ScrollToTop>
      <Helmet>
        <title>Sign Up | Progenitor Solutions</title>
      </Helmet>

      <main>
        <div className="w-full px-4 lg:w-11/12 xl:px-0 xl:w-9/12 2xl:w-[1280px] mx-auto h-full">
          <div className="grid grid-cols-2">
            {/* ============ LEFT COLUMN ============*/}
            <div className="px-8 col-span-2 lg:col-span-1">
              {/* Logo */}
              <div className="py-6">
                <img
                  className="mx-auto"
                  src={logo}
                  width="200"
                  height="36"
                  alt="logo"
                />
              </div>
              {/* form */}
              <form className="mb-6" onSubmit={checkoutFormSubmit}>
                {formResponseError && (
                  <div className="bg-red-100 text-center p-4 mb-2 rounded text-red-700 border border-red-200">
                    {formResponseError}
                  </div>
                )}
                {/* ============================ */}
                {/* form section account details */}
                {/* ============================ */}
                <div className="mb-4">
                  <div className="mb-2">
                    <h4 className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="w-6 h-6 mr-1"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                        />
                      </svg>
                      Account details
                    </h4>
                  </div>
                  <div className="mb-2">
                    <label
                      className="text-xs uppercase font-bold tracking-wider text-slate-500"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      id="email"
                      className=""
                      name="email"
                      type="text"
                      value={formData.email}
                      onChange={(e) => inputOnChange(e)}
                    />
                    {formErrors.email && (
                      <span className="text-red-600 text-sm">
                        {formErrors.email}
                      </span>
                    )}
                  </div>
                  <div className="mb-2">
                    <label
                      className="text-xs uppercase font-bold tracking-wider text-slate-500"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      id="password"
                      className=""
                      name="password"
                      type="password"
                      value={formData.password}
                      onChange={(e) => inputOnChange(e)}
                    />
                    {formErrors.password && (
                      <span className="text-red-600 text-sm">
                        {formErrors.password}
                      </span>
                    )}
                  </div>
                </div>
                {/* form section billing details */}
                <div className="mb-2">
                  <div className="mb-2">
                    <h4 className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="w-6 h-6 mr-1"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                        />
                      </svg>
                      Billing details
                    </h4>
                  </div>
                  <div className="grid grid-cols-2 gap-6">
                    <div className="mb-2">
                      <label
                        className="text-xs uppercase font-bold tracking-wider text-slate-500"
                        htmlFor="first_name"
                      >
                        First Name
                      </label>
                      <input
                        id="first_name"
                        className=""
                        name="first_name"
                        type="text"
                        value={formData.first_name}
                        onChange={(e) => inputOnChange(e)}
                      />
                      {formErrors.first_name && (
                        <span className="text-red-600 text-sm">
                          {formErrors.first_name}
                        </span>
                      )}
                    </div>
                    <div className="mb-2">
                      <label
                        className="text-xs uppercase font-bold tracking-wider text-slate-500"
                        htmlFor="last_name"
                      >
                        Last Name
                      </label>
                      <input
                        id="last_name"
                        className=""
                        name="last_name"
                        type="text"
                        value={formData.last_name}
                        onChange={(e) => inputOnChange(e)}
                      />
                      {formErrors.last_name && (
                        <span className="text-red-600 text-sm">
                          {formErrors.last_name}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="mb-2">
                    <label
                      className="text-xs uppercase font-bold tracking-wider text-slate-500"
                      htmlFor="address"
                    >
                      Address
                    </label>
                    <input
                      id="address"
                      className=""
                      name="address"
                      type="text"
                      value={formData.address}
                      onChange={(e) => inputOnChange(e)}
                    />
                    {formErrors.address && (
                      <span className="text-red-600 text-sm">
                        {formErrors.address}
                      </span>
                    )}
                  </div>
                  <div className="mb-2">
                    <label
                      className="text-xs uppercase font-bold tracking-wider text-slate-500"
                      htmlFor="city"
                    >
                      City
                    </label>
                    <input
                      id="city"
                      className=""
                      name="city"
                      type="text"
                      value={formData.city}
                      onChange={(e) => inputOnChange(e)}
                    />
                    {formErrors.city && (
                      <span className="text-red-600 text-sm">
                        {formErrors.city}
                      </span>
                    )}
                  </div>
                  <div className="grid grid-cols-2 gap-6">
                    <div className="mb-2">
                      <label
                        className="text-xs uppercase font-bold tracking-wider text-slate-500"
                        htmlFor="zip"
                      >
                        Zip Code
                      </label>
                      <input
                        id="zip"
                        className=""
                        name="zip"
                        type="text"
                        value={formData.zip}
                        onChange={(e) => inputOnChange(e)}
                      />
                      {formErrors.zip && (
                        <span className="text-red-600 text-sm">
                          {formErrors.zip}
                        </span>
                      )}
                    </div>
                    <div className="mb-2">
                      <label
                        className="text-xs uppercase font-bold tracking-wider text-slate-500"
                        htmlFor="phone"
                      >
                        Phone
                      </label>
                      <input
                        id="phone"
                        className=""
                        name="phone"
                        type="text"
                        value={formData.phone}
                        onChange={(e) => inputOnChange(e)}
                      />
                      {formErrors.phone && (
                        <span className="text-red-600 text-sm">
                          {formErrors.phone}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {/* ======= Header ======= */}
                <div className="mb-2">
                  <h4 className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-6 h-6 mr-1"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                      />
                    </svg>
                    Payment details
                  </h4>
                </div>
                {/* ====================== */}
                <div className="mb-2">
                  <label
                    className="text-xs uppercase font-bold tracking-wider text-slate-500"
                    htmlFor="card_type"
                  >
                    Card
                  </label>
                  <select
                    id="card_type"
                    className=""
                    name="card_type"
                    value={formData.card_type}
                    onChange={(e) => inputOnChange(e)}
                  >
                    <option>Select Card</option>
                    <option value="visa">Visa</option>
                    <option value="mastercard">Mastercard</option>
                    <option value="discover">Discover</option>
                  </select>
                  {formErrors.card_type && (
                    <span className="text-red-600 text-sm">
                      {formErrors.card_type}
                    </span>
                  )}
                </div>
                <div className="mb-2">
                  <label
                    className="text-xs uppercase font-bold tracking-wider text-slate-500"
                    htmlFor="card_number"
                  >
                    Card Number
                  </label>
                  <input
                    id="card_number"
                    className=""
                    name="card_number"
                    type="text"
                    maxLength="16"
                    value={formData.card_number}
                    onChange={(e) => inputOnChange(e)}
                  />
                  {formErrors.card_number && (
                    <span className="text-red-600 text-sm">
                      {formErrors.card_number}
                    </span>
                  )}
                </div>
                <div className="grid grid-cols-3 gap-6 mb-4">
                  <div className="mb-2">
                    <label
                      className="text-xs uppercase font-bold tracking-wider text-slate-500"
                      htmlFor="card_expire_month"
                    >
                      Month
                    </label>
                    <select
                      id="card_expire_month"
                      className=""
                      name="card_expire_month"
                      value={formData.card_expire_month}
                      onChange={(e) => inputOnChange(e)}
                    >
                      <option>{''}</option>
                      {months.map((item, key) => {
                        return (
                          <option key={`m-${key}`} value={item.code}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    {formErrors.card_expire_month && (
                      <span className="text-red-600 text-sm">
                        {formErrors.card_expire_month}
                      </span>
                    )}
                  </div>
                  <div className="mb-2">
                    <label
                      className="text-xs uppercase font-bold tracking-wider text-slate-500"
                      htmlFor="card_expire_year"
                    >
                      Year
                    </label>
                    <select
                      id="card_expire_year"
                      className=""
                      name="card_expire_year"
                      value={formData.card_expire_year}
                      onChange={(e) => inputOnChange(e)}
                    >
                      <option>{''}</option>
                      {years.map((item, key) => {
                        return (
                          <option key={`y-${key}`} value={item.code}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    {formErrors.card_expire_year && (
                      <span className="text-red-600 text-sm">
                        {formErrors.card_expire_year}
                      </span>
                    )}
                  </div>
                  <div className="mb-2">
                    <label
                      className="text-xs uppercase font-bold tracking-wider text-slate-500"
                      htmlFor="card_code"
                    >
                      CVV
                    </label>
                    <input
                      id="card_code"
                      className=""
                      name="card_code"
                      type="text"
                      value={formData.card_code}
                      onChange={(e) => inputOnChange(e)}
                    />
                    {formErrors.card_code && (
                      <span className="text-red-600 text-sm">
                        {formErrors.card_code}
                      </span>
                    )}
                  </div>
                </div>
                <button
                  className="bg-orange-500 py-4 px-8 font-bold text-white tracking-wider rounded uppercase w-full flex justify-center"
                  type="submit"
                  value="Submit"
                  disabled={loading}
                >
                  {!loading ? (
                    'Complete Signup'
                  ) : (
                    <img src={loader} alt="loader" width="24" />
                  )}
                </button>
              </form>
            </div>
            {/*============ RIGHT COLUMN ============*/}
            <div className="px-8 col-span-2 lg:col-span-1 lg:border-l border-slate-200 bg-slate-50">
              {packagesLoading ? (
                <></>
              ) : (
                <>
                  <div className="py-6 flex text-2xl font-bold border-b">
                    <div className="flex items-center">{product.name}</div>
                    <div className="ml-auto">{product.localised_price}</div>
                  </div>
                  <div className="py-6">
                    <div className="text-lg mb-6">{product.description}</div>
                    <ul className="text-lg">
                      {product?.features.map((item, key) => {
                        return (
                          <li className="py-1 flex items-center" key={key}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6 mr-2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                            {item.name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </ScrollToTop>
  );
};

export default SignupPage;
