import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

// {/* <div className="h-[150px] md:h-[300px] lg:h-[450px]" /> */}

export default function HeroBanner() {
  return (
    <section className="bg-no-repeat bg-center bg-hero-bg bg-slate-100 py-12">
      <div className="w-full px-4 lg:w-11/12 xl:px-0 xl:w-9/12 2xl:w-[1280px] mx-auto h-full">
        <div className="text-center flex flex-col items-center justify-center h-[220px] md:h-[300px] lg:h-[400px]">
          <h1 className="text-3xl md:text-4xl font-bold mb-4">
            Fast and Accurate Geolocation Lookups
          </h1>
          <div className="text-xl mb-6">
            Tailor software to users and enrich your reporting.
          </div>
          <HashLink
            className="bg-orange-500 py-4 px-8 font-bold text-white tracking-wider rounded uppercase w-fit"
            smooth
            to="/#packages"
          >
            Get Started Now
          </HashLink>
        </div>
      </div>
    </section>
  );
}
