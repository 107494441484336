import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '@/store/slices/userSlice';
import axios from 'axios';
import validateEmail from '@/utils/helpers';

// Assets
import logo from '@/assets/img/logo.svg';
import loader from '@/assets/img/loader.gif';

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const formDataModel = { email: '', password: '' };

  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [formData, setFormData] = useState(formDataModel);
  const [formResponseError, setFormResponseError] = useState(false);

  const loginUser = async (e) => {
    e.preventDefault();

    setLoading(true);

    const errors = {};

    setFormErrors([]);

    // Check required fields
    for (const data in formData) {
      if (!formData[data]) {
        errors[data] = 'This is field is require';
      }
    }

    // Validate email
    if (formData.email) {
      const emailAddress = validateEmail(formData.email);
      if (!emailAddress) {
        errors.email = 'Please enter a valid email address';
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setLoading(false);
    } else {
      try {
        const { data } = await axios.post(
          'https://jacone-progenitorgeoapi-backend-prod.donkeymob.com/api/1/site/login',
          // '/api/1/site/login',
          formData
        );

        if (data?.status === 200) {
          dispatch(setUser(data?.result?.user));
        }

        navigateTo('/admin');
      } catch (err) {
        if (err.response) {
          setFormResponseError(true);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  function inputOnChange(e) {
    const label = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [label]: value });
  }

  return (
    <>
      <Helmet>
        <title>Login | Progenitor Solutions</title>
      </Helmet>

      <section className="py-24">
        <div className="w-full px-4 lg:w-11/12 xl:px-0 xl:w-9/12 2xl:w-[1280px] mx-auto">
          <div className="w-full sm:w-[400px] mx-auto">
            <div className="mb-6">
              <img className="mx-auto" src={logo} alt="logo" width="250" />
            </div>
            <form onSubmit={loginUser}>
              {formResponseError && (
                <div className="bg-red-100 text-center p-4 mb-2 rounded text-red-700 border border-red-200">
                  You have entered the wrong email/password
                </div>
              )}
              <div className="mb-4">
                <label
                  className="text-xs uppercase font-bold tracking-wider text-slate-500"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  id="email"
                  className=""
                  name="email"
                  type="text"
                  value={formData.email}
                  onChange={(e) => inputOnChange(e)}
                />
                {formErrors.email && (
                  <span className="text-red-600 text-sm">
                    {formErrors.email}
                  </span>
                )}
              </div>
              <div className="mb-6">
                <label
                  className="text-xs uppercase font-bold tracking-wider text-slate-500"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  id="password"
                  className=""
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={(e) => inputOnChange(e)}
                />
                {formErrors.password && (
                  <span className="text-red-600 text-sm">
                    {formErrors.password}
                  </span>
                )}
              </div>
              <button
                className="bg-orange-500 py-4 px-8 font-bold text-white tracking-wider rounded uppercase w-full flex justify-center"
                type="submit"
                value="Submit"
                disabled={loading}
              >
                {!loading ? (
                  'Login'
                ) : (
                  <img src={loader} alt="loader" width="24" />
                )}
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginPage;
