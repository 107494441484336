import { Routes, Route } from 'react-router-dom';

import ScrollToTop from '@/components/ScrollToTop';

// layouts
import DefaultLayout from '@/layout/DefaultLayout';
import AdminLayout from '@/layout/AdminLayout';

// pages
import HomePage from '@/pages/index';
import AdminPage from '@/pages/admin/index';
import LoginPage from '@/pages/login';
import SignupPage from '@/pages/signup';
import ContactPage from '@/pages/contact';
import TermsPage from '@/pages/terms';
import PrivacyPage from '@/pages/privacy';
import Error404 from '@/pages/error404';

const renderRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup/:id" element={<SignupPage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/terms-and-conditions" element={<TermsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
      </Route>

      <Route element={<AdminLayout />}>
        <Route path="/admin" element={<AdminPage />} />
      </Route>

      <Route path="*" element={<DefaultLayout />}>
        <Route path="*" element={<Error404 />} />
      </Route>
    </Routes>
  );
};

export default renderRoutes;
