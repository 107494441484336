import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import AdminHeader from '@/components/AdminHeader';

// Compoents
import ScrollToTop from '@/components/ScrollToTop';
import ServicesBlock from './services';
import Footer from '@/components/Footer';

const AdminPage = () => {
  const user = useSelector((state) => state.user);

  return (
    <ScrollToTop>
      <Helmet>
        <title>Admin | Progenitor Solutions</title>
      </Helmet>

      <AdminHeader />

      <section className="py-12">
        <div className="w-full px-4 lg:w-11/12 xl:px-0 xl:w-9/12 2xl:w-[1280px] mx-auto">
          <h1 className="mb-6">
            Welcome {user.first_name} {user.last_name}
          </h1>
          <div className="mb-6 bg-blue-50 border border-blue-100 p-4 rounded">
            <span className="font-bold">API KEY:</span> {user.api_key}
          </div>
          <ServicesBlock />
        </div>
      </section>

      <Footer />
    </ScrollToTop>
  );
};

export default AdminPage;
