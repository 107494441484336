import React from 'react';
import { Link } from 'react-router-dom';

// assets
import logo from '@/assets/img/logo.svg';

const Footer = () => {
  return (
    <footer className="border-t border-slate-200 py-12">
      <div className="w-full px-4 lg:w-11/12 xl:px-0 xl:w-9/12 2xl:w-[1280px] mx-auto">
        <div className="text-center">
          <div className="mb-4">
            <img className="mx-auto" src={logo} alt="logo" width="200" />
          </div>
          <nav className="font-barlow uppercase mb-4">
            <Link
              className="inline-flex px-4 py-2 tracking-wider text-slate-800 font-bold text-xs"
              to="/terms-and-conditions"
            >
              Terms And Conditions
            </Link>
            <Link
              className="inline-flex px-4 py-2 tracking-wider text-slate-800 font-bold text-xs"
              to="/privacy-policy"
            >
              Privacy Policy
            </Link>
            <Link
              className="inline-flex px-4 py-2 tracking-wider text-slate-800 font-bold text-xs"
              to="/contact-us"
            >
              Contact Us
            </Link>
          </nav>
          <div className="text-sm opacity-70">
            Copyright {new Date().getFullYear()} Progenitor Solutions LLC. All
            Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
