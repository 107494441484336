import React from 'react';
import { Link } from 'react-router-dom';

// assets
import logo from '@/assets/img/logo.svg';

const AdminHeader = () => {
  return (
    <header className="bg-slate-100 lg:py-4">
      <div className="w-full px-4 lg:w-11/12 xl:px-0 xl:w-9/12 2xl:w-[1280px] mx-auto flex items-center">
        <div className="text-4xl text-white font-barlow" to="/">
          <img src={logo} width="200" height="32" alt="tempo flex logo" />
        </div>
        <div className="ml-auto hidden lg:block">
          <nav className="font-barlow uppercase">
            <Link
              className="inline-flex px-4 py-2 tracking-wider text-slate-800 font-bold text-sm"
              to="/"
            >
              Logout
            </Link>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
