import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '@/components/Header';
import { Link } from 'react-router-dom';

// Components
import Footer from '@/components/Footer';

// Assets
import brand from '@/assets/json/brand.json';

const PrivacyPage = () => {
  return (
    <>
      <Helmet>
        <title>Privacy policy | Progenitor Solutions</title>
      </Helmet>

      <Header />

      <section className="py-12">
        <div className="w-full px-4 lg:w-11/12 xl:px-0 xl:w-9/12 2xl:w-[1280px] mx-auto">
          <h1 className="mb-4">Privacy Policy</h1>
          <p>{brand.privacy}</p>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default PrivacyPage;
