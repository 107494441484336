import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export default function Nav() {
  const menu = [
    {
      label: 'Contact Us',
      url: '/contact-us',
    },
    {
      label: 'Login',
      url: '/login',
    },
  ];

  return (
    <nav className="font-barlow uppercase">
      <HashLink
        className="inline-flex px-4 py-2 tracking-wider text-slate-800 font-bold text-sm"
        smooth
        to="/#packages"
      >
        Pricing
      </HashLink>
      <HashLink
        className="inline-flex px-4 py-2 tracking-wider text-slate-800 font-bold text-sm"
        smooth
        to="/#packages"
      >
        Sign up
      </HashLink>
      {menu.map((item, key) => {
        return (
          <Link
            className="inline-flex px-4 py-2 tracking-wider text-slate-800 font-bold text-sm"
            to={item.url}
            key={`nav-item-${key}`}
          >
            {item.label}
          </Link>
        );
      })}
    </nav>
  );
}
