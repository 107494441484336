import React, { useState } from 'react';

// componets
import Nav from './components/Nav';
import MobileNav from './components/MobileNav';

// assets
import logo from '@/assets/img/logo.svg';

const Header = () => {
  const [mobileNav, setMobileNav] = useState(false);

  function onMobileNavClick() {
    setMobileNav(false);
  }

  return (
    <header className="bg-slate-50 border-b border-slate-200 lg:py-4">
      <div className="w-full px-4 lg:w-11/12 xl:px-0 xl:w-9/12 2xl:w-[1280px] mx-auto flex items-center">
        <div className="text-4xl text-white font-barlow" to="/">
          <img src={logo} width="200" height="32" alt="tempo flex logo" />
        </div>
        <div className="ml-auto hidden lg:block">
          <Nav />
        </div>
        <div className="ml-auto block lg:hidden">
          <button
            className="text-slate-700 p-6"
            onClick={() => setMobileNav(!mobileNav)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-9 h-9"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d={
                  mobileNav
                    ? 'M6 18L18 6M6 6l12 12'
                    : 'M3.75 9h16.5m-16.5 6.75h16.5'
                }
              />
            </svg>
          </button>
        </div>
      </div>
      {mobileNav && <MobileNav onClick={onMobileNavClick} />}
    </header>
  );
};

export default Header;
