import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    first_name: '',
    last_name: '',
    product: {},
    api_key: '',
  },
  reducers: {
    setUser: (state, { payload }) => {
      const { first_name, last_name, product, api_key } = payload;
      state.first_name = first_name;
      state.last_name = last_name;
      state.product = product;
      state.api_key = api_key;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
