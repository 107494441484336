import React, { useState } from 'react';

function renderSwitch(param) {
  switch (param) {
    case 'geo':
      return (
        <>
          <h2>Get Geo by IP</h2>
          <p>
            This endpoint will allow you to do a Geo request for a given IP
            Address.
          </p>
          <h5>Endpoint</h5>
          <div className="bg-slate-100 p-6 rounded-lg border border-slate-200 mb-4">
            https://www.progenitor-geo-api.com/api/1/geo/get-by-ip?api_key=
            <code className="text-amber-700">{`{api_key}`}</code>&ip=
            <code className="text-amber-700">{`{ip}`}</code>
          </div>
          <h5>Request</h5>
          <p>
            api_key Your personal API Key which is used to authenticate the
            request ip The IP address you would like to query against
          </p>
          <h5>Example Request</h5>
          <div className="bg-slate-100 p-6 rounded-lg border border-slate-200 mb-4">
            https://www.progenitor-geo-api.com/api/1/geo/get-by-ip?api_key=
            <code className="text-amber-700">{`{api_key}`}</code>
            &ip=142.251.32.78
          </div>
          <h5>Example Response</h5>
          <div className="bg-slate-100 p-6 rounded-lg border border-slate-200">
            <pre>
              <code>
                {JSON.stringify(
                  {
                    status: 200,
                    success: true,
                    elapsed_seconds: 0.72320103645324707,
                    query: {
                      ip: '104.22.65.247',
                    },
                    result: {
                      geo: {
                        continent: {
                          code: 'NA',
                          name: 'North America',
                          geoname_id: 6255149,
                        },
                        country: {
                          code: 'US',
                          name: 'United States',
                          geoname_id: 6252001,
                        },
                        city: {
                          name: 'Newark',
                          geoname_id: 5101798,
                        },
                        region: {
                          name: 'New Jersey',
                          code: 'NJ',
                          geoname_id: 5101760,
                        },
                        subdivisions: [
                          {
                            name: 'Essex',
                            code: null,
                            geoname_id: 5097707,
                          },
                        ],
                        postal_code: '07175',
                      },
                      coordinates: {
                        latitude: 40.735700000000001,
                        longitude: -74.172399999999996,
                      },
                      time: {
                        timezone: 'America/New_York',
                      },
                      isp: {
                        name: 'Cloudflare, Inc.',
                        organization: 'Cloudflare, Inc.',
                        connection_type: 'Corporate',
                        autonomous_system_number: 13335,
                        autonomous_system_organization: 'Cloudflare, Inc.',
                      },
                    },
                  },
                  undefined,
                  2
                )}
              </code>
            </pre>
          </div>
        </>
      );

    case 'list':
      return (
        <>
          <h2>Bulk Get Geo by IP</h2>
          <p>
            This endpoint will allow you to Get a list of geos from a list of
            multiple API keys Address.
          </p>
          <h5>Endpoint</h5>
          <div className="bg-slate-100 p-6 rounded-lg border border-slate-200 mb-4">
            https://www.progenitor-geo-api.com/api/1/geo/get-by-ips?api_key=
            <code className="text-amber-700">{`{api_key}`}</code>&ip=
            <code className="text-amber-700">{`{ip}`}</code>
          </div>
          <h5>Request</h5>
          <p>
            api_key Your personal API Key which is used to authenticate the
            request ip The IP address you would like to query against
          </p>
          <h5>Example Request</h5>
          <div className="bg-slate-100 p-6 rounded-lg border border-slate-200 mb-4">
            https://www.progenitor-geo-api.com/api/1/geo/get-by-ips?api_key=
            <code className="text-amber-700">{`{api_key}`}</code>
            &ip=142.251.32.78
          </div>
          <h5>Example Response</h5>
          <div className="bg-slate-100 p-6 rounded-lg border border-slate-200">
            <pre>
              <code>
                {JSON.stringify(
                  {
                    status: 200,
                    success: true,
                    elapsed_seconds: 0.72320103645324707,
                    query: {
                      ip: '104.22.65.247',
                    },
                    result: {
                      geo: {
                        continent: {
                          code: 'NA',
                          name: 'North America',
                          geoname_id: 6255149,
                        },
                        country: {
                          code: 'US',
                          name: 'United States',
                          geoname_id: 6252001,
                        },
                        city: {
                          name: 'Newark',
                          geoname_id: 5101798,
                        },
                        region: {
                          name: 'New Jersey',
                          code: 'NJ',
                          geoname_id: 5101760,
                        },
                        subdivisions: [
                          {
                            name: 'Essex',
                            code: null,
                            geoname_id: 5097707,
                          },
                        ],
                        postal_code: '07175',
                      },
                      coordinates: {
                        latitude: 40.735700000000001,
                        longitude: -74.172399999999996,
                      },
                      time: {
                        timezone: 'America/New_York',
                      },
                      isp: {
                        name: 'Cloudflare, Inc.',
                        organization: 'Cloudflare, Inc.',
                        connection_type: 'Corporate',
                        autonomous_system_number: 13335,
                        autonomous_system_organization: 'Cloudflare, Inc.',
                      },
                    },
                  },
                  undefined,
                  2
                )}
              </code>
            </pre>
          </div>
        </>
      );

    case 'state':
      return (
        <>
          <h2>Get Geo by Zipcode</h2>
          <p>This endpoint will allow you to Get a State from a ZIP code.</p>
          <h5>Endpoint</h5>
          <div className="bg-slate-100 p-6 rounded-lg border border-slate-200 mb-4">
            https://www.progenitor-geo-api.com/api/1/geo/get-state-by-zip?api_key=
            <code className="text-amber-700">{`{api_key}`}</code>&ip=
            <code className="text-amber-700">{`{ip}`}</code>
          </div>
          <h5>Request</h5>
          <p>
            api_key Your personal API Key which is used to authenticate the
            request ip The IP address you would like to query against
          </p>
          <h5>Example Request</h5>
          <div className="bg-slate-100 p-6 rounded-lg border border-slate-200 mb-4">
            https://www.progenitor-geo-api.com/api/1/geo/get-state-by-zip?api_key=
            <code className="text-amber-700">{`{api_key}`}</code>
            &ip=142.251.32.78
          </div>
          <h5>Example Response</h5>
          <div className="bg-slate-100 p-6 rounded-lg border border-slate-200">
            <pre>
              <code>
                {JSON.stringify(
                  {
                    status: 200,
                    success: true,
                    elapsed_seconds: 0.72320103645324707,
                    query: {
                      ip: '104.22.65.247',
                    },
                    result: {
                      geo: {
                        continent: {
                          code: 'NA',
                          name: 'North America',
                          geoname_id: 6255149,
                        },
                        country: {
                          code: 'US',
                          name: 'United States',
                          geoname_id: 6252001,
                        },
                        city: {
                          name: 'Newark',
                          geoname_id: 5101798,
                        },
                        region: {
                          name: 'New Jersey',
                          code: 'NJ',
                          geoname_id: 5101760,
                        },
                        subdivisions: [
                          {
                            name: 'Essex',
                            code: null,
                            geoname_id: 5097707,
                          },
                        ],
                        postal_code: '07175',
                      },
                      coordinates: {
                        latitude: 40.735700000000001,
                        longitude: -74.172399999999996,
                      },
                      time: {
                        timezone: 'America/New_York',
                      },
                      isp: {
                        name: 'Cloudflare, Inc.',
                        organization: 'Cloudflare, Inc.',
                        connection_type: 'Corporate',
                        autonomous_system_number: 13335,
                        autonomous_system_organization: 'Cloudflare, Inc.',
                      },
                    },
                  },
                  undefined,
                  2
                )}
              </code>
            </pre>
          </div>
        </>
      );
  }
}

const ServicesBlock = () => {
  const [service, setService] = useState('geo');

  return (
    <>
      <div className="grid grid-cols-6">
        <div className="col-span-6 lg:col-span-1 border-b lg:border-b-0 lg:border-r flex lg:flex-col">
          <button
            className={`py-2 px-4 font-bold text-left ${
              service === 'geo' && 'text-blue-700'
            }`}
            onClick={() => setService('geo')}
          >
            Geo Service
          </button>
          <button
            className={`py-2 px-4 font-bold text-left ${
              service === 'list' && 'text-blue-700'
            }`}
            onClick={() => setService('list')}
          >
            Bulk Get Geo by IP
          </button>
          <button
            className={`py-2 px-4 font-bold text-left ${
              service === 'state' && 'text-blue-700'
            }`}
            onClick={() => setService('state')}
          >
            Get Geo by Zipcode
          </button>
        </div>
        <div className="col-span-6 lg:col-span-5 px-4 lg:px-6 py-4">
          {renderSwitch(service)}
        </div>
      </div>
    </>
  );
};

export default ServicesBlock;
