import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '@/components/Header';
import { Link } from 'react-router-dom';

// Compoents
import Footer from '@/components/Footer';

// Assets
import brand from '@/assets/json/brand.json';

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us | Progenitor Solutions</title>
      </Helmet>

      <Header />

      <section className="py-12">
        <div className="w-full px-4 lg:w-11/12 xl:px-0 xl:w-9/12 2xl:w-[1280px] mx-auto">
          <h1 className="mb-4">Contact Us</h1>
          <p>Got questions or concerns? Feel free to reach out!</p>
          <div className="mb-4">
            <div className="font-bold text-slate-800 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-5 h-5 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
              Email
            </div>
            <div>
              <a className="text-blue-700">{brand.email}</a>
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold text-slate-800 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-5 h-5 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                />
              </svg>
              Phone Number
            </div>
            <div>{brand.phone_number}</div>
          </div>
          <div className="mb-4">
            <div className="font-bold text-slate-800 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                />
              </svg>
              Address
            </div>
            <div>
              {brand.address_street}
              <br />
              {brand.address_street_2}
              <br />
              {brand.address_city}
              <br />
              {brand.address_zip}
              <br />
              {brand.address_state}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ContactPage;
