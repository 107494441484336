import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// compoents
import Header from '@/components/Header';
import HeroBanner from '@/components/HeroBanner';
import Packages from '@/components/Packages';
import Footer from '@/components/Footer';

// assets
import products from '@/assets/json/products.json';

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>Geolocation API | Progenitor Solutions</title>
      </Helmet>

      <Header />
      <HeroBanner />

      {/* section one */}
      <section className="py-12 bg-slate-50 border-b border-t border-slate-200">
        <div className="w-full px-4 lg:w-11/12 xl:px-0 xl:w-9/12 2xl:w-[1280px] mx-auto">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 md:col-span-3 lg:col-span-2  text-center">
              <h4 className="flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-8 h-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                  />
                </svg>
                Realtime Lookup
              </h4>
              <div>
                Get Contintent, Country, City, Timezone, ISP and more in real
                time.
              </div>
            </div>
            <div className="col-span-6 md:col-span-3 lg:col-span-2  text-center">
              <h4 className="flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-8 h-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
                  />
                </svg>
                Available
              </h4>
              <div>
                Our cloud based API is always up and always ready with blazing
                fast lookup times.
              </div>
            </div>
            <div className="col-span-6 md:col-span-3 lg:col-span-2  text-center">
              <h4 className="flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-8 h-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 01-1.161.886l-.143.048a1.107 1.107 0 00-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 01-1.652.928l-.679-.906a1.125 1.125 0 00-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 00-8.862 12.872M12.75 3.031a9 9 0 016.69 14.036m0 0l-.177-.529A2.25 2.25 0 0017.128 15H16.5l-.324-.324a1.453 1.453 0 00-2.328.377l-.036.073a1.586 1.586 0 01-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 01-5.276 3.67m0 0a9 9 0 01-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25"
                  />
                </svg>
                Customer Support
              </h4>
              <div>
                When something goes wrong you want a human being who can help
                you through.
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section two packages */}
      <section id="packages" className="py-12 bg-white">
        <div className="w-full px-4 lg:w-11/12 xl:px-0 xl:w-9/12 2xl:w-[1280px] mx-auto">
          <div className="text-center mb-12">
            <h2>Select The Right Plan For You</h2>
          </div>
          <Packages products={products} />
        </div>
      </section>

      <Footer />
    </>
  );
};

export default HomePage;
