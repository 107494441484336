import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Packages = () => {
  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState(null);

  const getPackages = async () => {
    try {
      const { data } = await axios.post(
        'https://jacone-progenitorgeoapi-backend-prod.donkeymob.com/api/1/site/get-products'
      );

      setPackages(data?.result?.products);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPackages();
  }, []);

  return (
    <div className="grid grid-cols-6 gap-6">
      {loading
        ? 'Loading...'
        : packages.map((item, key) => (
            <div className="col-span-6 md:col-span-3 lg:col-span-2" key={key}>
              <div className="h-full relative">
                <div className="bg-blue-700 rounded-t-lg p-6 text-center h-[320px] flex flex-col">
                  <h2 className="text-white mb-4 flex items-center justify-center">
                    {item.name}
                  </h2>
                  <div className="text-white mb-6 font-bold">
                    <span className="text-3xl">{item.localised_price}</span> per
                    year
                  </div>
                  <div className="text-white mb-6 leading-6 text-base">
                    {item.description}
                  </div>
                  <Link
                    className="border border-green-700 bg-gradient-to-t from-green-600 to-green-500 py-4 px-8 font-bold text-white tracking-wider rounded uppercase block mt-auto"
                    to={`/signup/${item.id}`}
                  >
                    Sign Up
                  </Link>
                </div>
                <div className="p-6 bg-slate-100 rounded-b-lg border border-slate-200 h-[380px]">
                  <ul className="text-lg">
                    {item.features.map((item, key) => {
                      return (
                        <li
                          className="py-1 flex items-center text-base"
                          key={key}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 mr-2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M4.5 12.75l6 6 9-13.5"
                            />
                          </svg>
                          {item.name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          ))}
    </div>
  );
};

export default Packages;
