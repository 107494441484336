import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';

export default function MobileNav({ onClick }) {
  const menu = [
    {
      label: 'Contact Us',
      url: '/contact-us',
    },
    {
      label: 'Login',
      url: '/login',
    },
  ];

  return (
    <div className="bg-slate-50 h-screen">
      <nav className="font-barlow uppercase flex flex-col">
        <HashLink
          className="inline-flex px-4 py-6 tracking-wider text-slate-800 font-bold text-sm border-b border-slate-200"
          onClick={onClick}
          to="/#packages"
        >
          Pricing
        </HashLink>
        <HashLink
          className="inline-flex px-4 py-6 tracking-wider text-slate-800 font-bold text-sm border-b border-slate-200"
          onClick={onClick}
          to="/#packages"
        >
          Sign up
        </HashLink>
        {menu.map((item, key) => {
          return (
            <Link
              className="inline-flex px-4 py-6 tracking-wider text-slate-800 font-bold text-sm border-b border-slate-200"
              to={item.url}
              onClick={onClick}
              key={`nav-item-${key}`}
            >
              {item.label}
            </Link>
          );
        })}
      </nav>
    </div>
  );
}

MobileNav.propTypes = {
  onClick: PropTypes.func.isRequired,
};
